.loadingIndicator {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 50;
}
.loadingIndicator p {
  display: block;
  top: 50%;
  color: #fff;
  position: relative;
  text-align: center;
  font-family: "Work Sans";
  font-size: 18px;
}
