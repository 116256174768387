.searchBar {
  margin-bottom: 20px;
  position: relative;
}

.searchBar .react-autosuggest__suggestions-container {
  position: absolute;
  width: 100%;
  z-index: 10;
  max-height: 1vh;
}

.searchBar .react-autosuggest__input {
  padding: 20px 20px;
  padding-left: 45px;
  font-size: 20px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 3px;
  border: none;
  font-family: "Work Sans", sans-serif;
  margin: 0px;
}
.searchBar .react-autosuggest__input--open {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.searchBar .react-autosuggest__input:focus {
  border: none;
  outline: 0;
}

.searchBar .react-autosuggest__suggestions-list {
  margin: 0px;
  padding: 0;
  list-style: none;
  background-color: #fff;
  border-top: 1px solid #ccc;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.searchBar .react-autosuggest__suggestions-list li {
  padding: 20px 20px;
  font-family: "Work Sans", sans-serif;
  cursor: pointer;
}
.searchBar .react-autosuggest__suggestions-list li.react-autosuggest__suggestion--highlighted {
  background-color: #005672;
  color: #fff;
}

.react-autosuggest__section-title {
  padding: 10px 15px;
  font-family: "Work Sans", sans-serif;
  background-color: #005672;
  color: #fff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  position: relative;
}

.searchIcon {
  position: absolute;
  left: 15px;
  top: 22px;
  font-size: 20px;
  color: #ccc;
}

.filterButton {
  position: absolute;
  right: 10px;
  top: 9px;
  display: inline-block;
  border: 1px solid #e4e4e4;
  padding: 10px 20px;
  border-radius: 3px;
  font-size: 15px;
}

.filterButton i {
  margin-right: 6px;
  color: #005672;
  font-size: 14px;
}

.filterButton.filtered {
  background-color: #005672;
  color: #fff;
  border: 1px solid pink;
}

.filterButton.filtered i {
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .searchBar-wrapper {
    float: none;
  }

  .filterButton {
    top: 3px;
    right: 3px;
  }

  .searchBar {
    margin-bottom: 0px;
    margin-top: 10px;
  }

  .searchBar .react-autosuggest__input {
    border-radius: 0;
    font-size: 14px;
    padding: 16px 16px;
    padding-left: 45px;
  }

  .searchIcon {
    top: 16px;
    font-size: 16px;
  }
}
