header {
  top: 0px;
  left: 0px;
  right: 0px;
  height: 100px;
  z-index: 10;
  padding-top: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #fff;
  background-image: url("../assets/images/obl-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
  background-color: rgba(0, 0, 0, 0.6);
}

.headerLink {
  margin-left: 30px;
  display: inline-block;
}

.headerImage {
  width: 155px;
}

a.header-button {
  display: block;
  float: right;
  margin-right: 30px;
  margin-top: 6px;
  padding: 21px 20px;
  border-radius: 3px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  color: #fff;
  height: auto;
  text-decoration: none;
  transition: border-color 0.4s;
  cursor: pointer;
}
a.header-button i.fa {
  color: rgba(255, 255, 255, 0.6);
  margin-left: 5px;
  transition: color 0.4s;
}

a.header-button:hover i.fa {
  color: rgba(255, 255, 255, 1);
}

a.header-button:hover {
  border: 2px solid rgba(255, 255, 255, 1);
}

.map-label {
  width: auto;
  display: inline-block;
  background: #865d00;
  font-family: "Work Sans", sans-serif;
  font-weight: 100;
  font-size: 18px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  line-height: 16px;
  padding: 6px;
  -webkit-transform: rotate(-20deg);
  transform: rotate(-20deg);
  position: relative;
  top: -45px;
  right: 35px;
  color: #fff;
}

@media only screen and (min-width: 767px) {
  .searchBar-wrapper {
    float: right;
    margin-right: 30px;
    margin-top: 6px;
  }

  header {
    height: auto;
    position: absolute;
  }
}

@media only screen and (max-width: 767px) {
  header {
    height: auto;
    padding-top: 15px;
    position: absolute;
  }
  a.header-button {
    float: right;
    font-size: 10px;
    font-weight: bold;
    margin-right: 10px;
    margin-top: 10px;
    padding: 8px 8px;
  }

  a.header-button i.fa {
    margin-left: 2px;
  }
  .headerImage {
    width: 100px;
  }

  .searchBar-wrapper {
    float: none;
  }

  .headerLink {
    margin-left: 20px;
  }

  .map-label {
    width: auto;
    display: inline-block;
    background: #865d00;
    font-family: "Work Sans", sans-serif;
    font-weight: 100;
    font-size: 14px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    line-height: 16px;
    padding: 6px;
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
    position: relative;
    top: -30px;
    right: 35px;
    color: #fff;
  }
}
