.addToHome-wrapper {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: auto;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 100;
  padding: 20px 15px;
  padding-bottom: 10px;
  box-shadow: 0px -2px 3px rgba(0, 0, 0, 0.1);
}

.addToHome-wrapper h3 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans";
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 4px;
}

.addToHome-wrapper p {
  line-height: 22px;
  font-size: 15px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
}

.addToHome-wrapper i.fas {
  float: right;
  margin-right: 10px;
}

.shareSheet-icon::after {
  content: "";
  background: url("../assets/images/shareSheet-icon.png") no-repeat;
  height: 27px;
  width: 19px;
  display: inline-block;
  margin: 0px 6px;
  position: relative;
  top: 2px;
}
