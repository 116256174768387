.filterContainer {
  z-index: 5;
  background-color: #fff;
}

.filterContainer.hidden {
  display: none;
}

.filterContainer h3 {
  font-size: 22px;
  margin: 0px;
  margin-top: 5px;
  display: inline-block;
}

.filterContainer .titleContainer {
  padding: 15px;
  border-bottom: 1px solid #e4e4e4;
  user-select: none;
}

.filterContainer .titleContainer .collapseToggle {
  float: right;
  font-family: "Work Sans", sans-serif;
  color: #5f5f5f;
  cursor: pointer;
}

.filterContainer .titleContainer .collapseToggle span {
  margin-right: 18px;
}

.filterContainer .titleContainer i {
  color: #005672;
  top: 4px;
  right: 8px;
  position: relative;
  font-size: 25px;
}

.filterSelected {
  background-color: #005672;
  color: #fff;
}

.filterElements {
  padding: 20px 10px;
}

.filterElement {
  cursor: pointer;
  font-weight: 400;
  font-family: "Work Sans", sans-serif;
  border: 1px solid #ccc;
  padding: 12px 20px;
  border-radius: 20px;
  display: inline-block;
  margin: 10px 5px;
}
@media only screen and (max-width: 767px) {
  .filterContainer h3 {
    font-size: 16px;
  }

  .filterContainer {
    -webkit-transform: tranzlateZ(0);
    -webkit-overflow-scrolling: touch;
  }

  .filterElement {
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    font-family: "Work Sans", sans-serif;
    border: 1px solid #ccc;
    padding: 8px 14px;
    border-radius: 20px;
    display: inline-block;
    margin: 6px 4px;
  }
}
