.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  pointer-events: none;
}

.drawer {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: flex-start;
  overflow: scroll;
  margin-left: 20px;
  margin-top: 20px;
}

.drawer-right {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 375px;
  z-index: 5;
  padding: 10px 10px;
  overflow: scroll;
}

.box {
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  width: 400px;
  pointer-events: all;
}

.marker {
  width: 15px;
  height: 15px;
  background-color: #005672;
  border-radius: 30px;
  border: 7px solid rgba(255, 255, 255, 0.6);
  cursor: pointer;
  transition: background-color 0.2s;
  background-clip: padding-box;
}

.marker.selected {
  background-color: rgba(255, 81, 81, 0.85);
}

.markerLabel {
  background-color: #fff;
  position: relative;
  left: 26px;
  padding: 5px 8px;
  border-radius: 3px;
  color: #008cba;
  font-weight: bold;
  width: max-content;
  display: inline-block;
  top: -5px;
}

.clusterMarker {
  text-align: center;
  color: #fff;
  font-weight: bold;
  width: 30px;
  height: 30px;
}

.cluserNum {
  font-size: 16px;
  position: relative;
  top: 4px;
}

span.info {
  display: inline-block;
  height: 30px;
  left: 0px;
  right: 0px;
  top: 0px;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 10;
}

.mapTypeControl {
  bottom: 40px;
  left: 50px;
  position: absolute;
  border-radius: 10px;
  z-index: 5;
}

.mapTypeControlButton {
  display: inline-block;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 3px 0px 0px 3px;
  background: linear-gradient(180deg, #fafafa 0%, #ededed 100%);
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
  border-radius: 0px 3px 3px 0px;
}

.mapTypeControlButton.selected {
  background: linear-gradient(180deg, #e2e2e2 0%, #fafafa 100%);
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
}

.mapTypeControlButton:first-child {
  border-right: 1px solid #e0e0e0;
  border-radius: 3px 0px 0px 3px;
}

.mapboxgl-ctrl-geolocate {
  width: 50px;
  height: 50px;
}

@media only screen and (min-width: 767px) {
  .mapboxgl-ctrl-top-right {
    padding-top: 100px;
  }
  .mobile {
    display: none !important;
  }

  .mapboxgl-map {
    height: 100%;
  }
  .content {
    top: 100px;
  }
}
@media only screen and (max-width: 767px) {
  .mapboxgl-map {
    /*height: calc(100% - 141px) !important;*/
  }
  .mapboxgl-ctrl-top-right {
    top: inherit !important;
    margin-bottom: 45px;
    bottom: 0;
  }
  .App {
    overflow: hidden;
  }
  .content {
    top: 124px;
    pointer-events: none;
  }
  .content.shouldAllowTouch {
    pointer-events: all;
  }

  .drawer {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-end;
    overflow-y: scroll;
    align-items: stretch;
    margin: 0;
    justify-content: flex-start;
    height: calc(100% - 50px);
    padding-top: 20px;
    padding-bottom: 30px;
    -webkit-overflow-scrolling: touch;
  }
  .searchBar {
    width: 100%;
    float: none;
  }

  .box {
    width: auto;
  }

  .infoOverlay {
    margin: 0px 10px;
  }

  .infoOverlayContent {
  }

  .mapTypeControlButton {
    padding: 8px 18px;
    font-size: 14px;
  }
}
